@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'animate.css';

@layer base {
  html {
    font-family: "IM Fell French Canon", serif;
  }
}

img {
  display: block;
  margin: 8px auto;
  width: 48px;
  height: auto;
}

.navItem {
  display: block;
  text-align: center;
  position: absolute;
  height: 210px;
  top: 50%;
  margin-top: -190px;
  width: 100%;

   a {
     font-size: 32px;
     display: block;
     line-height: 65px;
     height: 65px;
     position: relative;
     text-decoration: none;

     i {
      transition: all 0.3s ease-out;
     }

     &:hover {
       color: rgb(2, 13, 44);

       svg {
         opacity: 0
       }

       &:after {
         opacity: 1;
       }
     }

    &:after {
      content: '';
      font-size: 15px;
      position: absolute;
      bottom: 0;
      display:block;
      width: 100%;
      text-align: center;
      opacity: 0;
      transition: all 0.3 ease-out;
    }

    &:nth-child(1) {
      &::after {
        content: "ABOUT"
      }
    }

    &:nth-child(2) {
      &::after {
        content: "PROJECTS"
      }
    }

    &:nth-child(3) {
      &::after {
        content: "PROJECTS"
      }
    }

    &:nth-child(4) {
      &::after {
        content: "RESUME"
      }
    }

    &:nth-child(5) {
      &::after {
        content: "CONTACT"
      }
    }
  }
}

.contacts {
  position:absolute;
  bottom: 20px;
  width: 100%;
  display: block;
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0;

  li {
    a {
      padding: 8px 0;
      display: block;
      font-size: 28px;
      line-height: 16px;

      &:hover svg {
        color: rgb(20, 78, 240)
      }
    }

  }
}

.navItem > .active {
  svg {
    color: rgb(2, 13, 44)
  }
}

.top {
  background: black;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
}

.bottom {
  margin-top: -25vh;
  background: url('/components/images/about3.jpg') center/cover;
  clip-path: polygon(1 0, 100% 0, 100% 100%, 0 100%);
}

#bio {
animation: bounceInDown 3s 0s;
}

.contact-links {
  justify-content: center;
  width: 100%;
  display: flex;
  list-style: none;
  text-align: center;
  margin: 0;

  li {
    a {
      padding: 2px 8px;
      display: block;
      font-size: 28px;
      line-height: 16px;

      &:hover svg {
        color: rgb(20, 78, 240)
      }
    }

  }
}

.button{
  position:relative;
  display:inline-block;
  padding:0 50px 0 25px;
  height:51px;width:120px;
  text-decoration:none;
  text-align:center;
  line-height:51px;
  text-transform:uppercase;
  top:calc(50% - 20px);
}
.button:before { 
  border-right: 55px solid #575757;
  border-left: 65px solid #f59999;
  border-bottom: 51px solid transparent;
  opacity: 0.45;
}

.button.left {
  background:rgba(255,255,255,.65);color:#000;

  &:hover {
    background:rgb(29, 119, 192);
    color: white;
    opacity: 0.9;
  }
}
.button.right {
  background:rgba(0,0,0,.85);color:#fff;

  &:hover {
    background:rgb(51, 202, 157);
    color: black;
    opacity: 0.9;
  }
}
    
.button:before, .button:after {
  content: "";    
  display: block;    
  position: absolute;    
  top: 0;    
  right: 0;
}


.bg-project1 {
  background: url('/components/images/gitfit.png') center/cover;
  z-index: 1;
  margin-right: -12vw;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);

  &:hover {
    -webkit-filter: brightness(90%)
  }
}
.bg-project2 {
  background: url('/components/images/proj2.png') center/cover;
  z-index: 1;
  margin-right: -12vw;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);

  &:hover {
    -webkit-filter: brightness(90%)
  }
}
.bg-project3 {
  background: url('/components/images/proj1.png') center/cover;
  z-index: 1;
  margin-right: -12vw;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);

  &:hover {
    -webkit-filter: brightness(90%)
  }
}
.bg-project4 {
  background: url('/components/images/proj5.png') center/cover;
  z-index: 1;
  margin-right: -12vw;
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);

  &:hover {
    -webkit-filter: brightness(90%)
  }
}

.bg-repo {
  background: url('/components/images/github.png') center/cover;

  &:hover {
    -webkit-filter: brightness(90%)
  }
}

#resume {
  background: url('/components/images/resume.jpg') center/cover;
  // Photo by Alexandru Acea on Unsplash
}

#contact {
  background: url('/components/images/contact.jpg') center/cover;
}

#flash {
  animation: zoomIn .5s
}

#pulse {
  animation: zoomInDown .5s
}

@media only screen and (max-width: 280px) {

  #contact {
    height: fit-content
  }

}

@media only screen and (max-width: 767px) {
  .top {
    background: black;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 70%)
  }
  
  .bottom {
    background: url('/components/images/about3.jpg') center;
  }

  nav {
    display:none;
  }

  .bg-project1 {
    margin-right: -16vw;
  }
  .bg-project2 {
    margin-right: -16vw;
  }
  .bg-project3 {
    margin-right: -16vw;
  }
  .bg-project4 {
    margin-right: -16vw;
  }

  .button{
    position:relative;
    display:inline-block;
    padding:0 0px 0 1px;
    height:31px;width:80px;
    text-decoration:none;
    text-align:center;
    line-height:31px;
    text-transform:uppercase;
    top:calc(50% - 20px);
  }
  .button:before { 
    border-right: 35px solid #575757;
    border-left: 45px solid #f59999;
    border-bottom: 31px solid transparent;
    opacity: 0.45;
  }
  
  .button.left {
    background:rgba(255,255,255,.65);color:#000;
  
    &:hover {
      background:rgb(29, 119, 192);
      color: white;
      opacity: 0.9;
    }
  }
  .button.right {
    background:rgba(0,0,0,.85);color:#fff;
  
    &:hover {
      background:rgb(51, 202, 157);
      color: black;
      opacity: 0.9;
    }
  }
      
  .button:before, .button:after {
    content: "";    
    display: block;    
    position: absolute;    
    top: 0;    
    right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .top {
    background: black;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%)
  }
  
  .bottom {
    margin-top: -33vh;
    background: url('/components/images/about3.jpg') center/cover;
    clip-path: polygon(1 0, 100% 0, 100% 100%, 0 100%);
  }
}

@media only screen and (min-width: 872px) {
  .top {
    background: black;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 63%);
  }
  
  .bottom {
    margin-top: -33vh;
    background: url('/components/images/about3.jpg') center/cover;
    clip-path: polygon(1 0, 100% 0, 100% 100%, 0 100%);
  }
}